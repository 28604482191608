<template>
  <div class="subpage">

    <FrameMD
        :posY="posY"
        :transitionPhoto="false"
        :staticPhoto="true"
        :photo="pageContent.section0.photo"
        :photoHeight="'500px'"
        :header="pageContent.section0.header"
        :text="pageContent.section0.text"
    ></FrameMD>
    
    <div class="container" ref="themes">
      <div
        v-for="(theme, index) in discographyThemes" :key="index"
      >
        <FrameAlbum
          :title="theme.title"
          :date="theme.date"
          :link="theme.link"
          :imgName="theme.imgName"
          :description="theme.description"
          :numberOfSongs="theme.numberOfSongs"
          :songs="theme.songs"
          :posY="posY"
          :transitionPhoto="false"
          :reversed="index % 2 == 0 ? false : true"
          :style="{marginBottom: '40px'}"
          :button="true"
        ></FrameAlbum>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FrameMD from '@/components/FrameMD'
import FrameAlbum from '@/components/FrameAlbum'

export default {
  name: 'Discography',
  props: ['posY'],
  components: {FrameMD, FrameAlbum},
  data () {
      return {
        
      }
  },
  computed: {
    ...mapState({
      pageContent: state => state.data.discography,
      discography: state => state.discography
    }),
    ...mapGetters(['discographyThemes'])
  },
  methods: {
    checkSticky () {
      if (this.posY >= this.$refs.themes.offsetTop) {
        this.$refs.themes.style.boxShadow = '0px 15px 10px -15px #555'
      }
      else {
        this.$refs.themes.style.boxShadow = 'none'
      }
    }
  },
  created () {
    window.addEventListener('scroll', this.checkSticky)
  },
  destroyed() {
    window.removeEventListener('scroll', this.checkSticky)
  }
}

</script>

<style lang="scss" scoped>

.header {
    margin: 20px 0 0 0;
    font-family: "PT Serif", serif;
    font-size: 1.7rem;
    color: #EB7054;
    text-align: center;
    background-color: white;
    @media screen and (min-width: 600px) {
        margin: 20px 0 15px 0;
    }
    @media screen and (min-width: 1200px) {
        margin: 80px 0 15px 0;
        font-size: 2.5rem;
    }
}
.container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 100px auto;
  box-shadow: none !important;
}
.album-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 50px auto 100px auto;
}

</style>