<template>
  <section
    ref="section"
    :style="{flexDirection: flexDirection}"
  >
    <div
      class="photo"
      :style="{
        backgroundImage: 'url(/img/albums/' + imgName + ')',
        margin: photoMargin
      }"
    ></div>
    <div
      class="frame"
      :style="{margin: frameMargin, padding: framePadding}"
    >
      <h1>{{ title }}</h1>
      <p v-if="description != ''"
      >{{ description }}</p>
      <p v-if="date != ''"
      >Data wydania: <span>{{ date }}</span></p>
      <p
      >Liczba utworów: <span>{{ numberOfSongs }}</span></p>
      <router-link
        :to="{ name: 'AlbumPage', params: { albumLink: link } }"
      >
        <button
          v-if="button"
          type="button"
          class="btn-primary"
        >Zobacz</button>
      </router-link>
    </div>
  </section>
</template>

<script>

export default {
  name: 'FrameAlbum',
  props: ['posY', 'songs', 'transitionPhoto', 'title', 'date', 'link', 'imgName', 'description', 'numberOfSongs', 'button', 'reversed'],
  data: () => ({
    
  }),
  computed: {
    windowWidth () {
        return this.$store.state.windowWidth
    },
    flexDirection () {
      if (this.windowWidth >= 960) {
        return this.reversed ? 'row-reverse' : 'row'
      }
      else if (this.windowWidth >= 600) {
        return 'row'
      }
      else {
        return 'column'
      }
    },
    frameMargin () {
      if (this.windowWidth >= 960) {
        return this.reversed ? '25px -75px 0 0' : '25px 0 0 -75px'
      }
      else if (this.windowWidth >= 600) {
        return '0 auto 0 5px'
      }
      else {
        return '-15px 5px 0 5px'
      }
    },
    framePadding () {
      if (this.windowWidth >= 960) {
        return this.reversed ? '30px 100px 0 50px' : '30px 50px 0 100px'
      }
      else {
        return '25px'
      }
    },
    photoMargin () {
      if (this.windowWidth >= 960) {
        return this.reversed ? '0 5px 0 0' : '0 0 0 5px'
      }
      else if (this.windowWidth >= 600) {
        return '0 0 0 auto'
      }
      else {
        return '0 auto'
      }
    }
  },
  methods: {
    checkFrame () {
      if(this.$refs.section) {
        var y = this.$refs.section.offsetTop
        if (y >= this.posY) {
          this.showFrame()
        }
      }
    },
    showFrame () {
      this.$refs.section.style.opacity = 1
      this.$refs.section.style.transform = 'translateX(0)'
    },
    setTransform () {
      if(this.$refs.section && this.reversed) {
        this.$refs.section.style.transform = 'translateX(200px)'
      }
    }
  },
  mounted () {
    this.setTransform()
    if (!this.transitionPhoto) {
      this.showFrame()
    }
  },
  created () {
    window.addEventListener('scroll', this.checkFrame)
  }
}
</script>

<style scoped lang="scss">

section {
  display: flex;
  opacity: 0;
  transform: translateX(-200px);
  transition: 2s all ease-in-out;
}
.photo {
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    width: 300px;
    z-index: 0;
    @media screen and (min-width: 1071px) {
      height: 421px;
      width: 421px;
    }
}
.frame {
  border: 1px solid #444;
  z-index: -1;
  text-align: center;
  @media screen and (min-width: 600px) {
      flex-basis: 50%;
      text-align: inherit;
  }
  @media screen and (min-width: 960px) {
      flex-basis: 720px;
      height: 260px;
  }
}
h1 {
    font-family: 'Cormorant Garamond', serif;
    font-size: 3rem;
    letter-spacing: 1px;
    color: #333;
    font-weight: 300;
    @media screen and (min-width: 1400px) { 
      font-size: 3rem;
      letter-spacing: 2px;
    }
}
p {
    margin: 15px 0 20px 0;
    font-size: 1.3rem;
    line-height: 2.8rem;
    color: #666;
    @media screen and (min-width: 1400px) { 
      font-size: 1.3rem;
    }
}
span {
  font-weight: bold;
}
.text-highlight {
  color: #EB7054;
  font-weight: bold;
}
.eng-link {
  color: #EB7054;
}

</style>
